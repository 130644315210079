<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import { SweetModal } from 'sweet-modal-vue'
import VuePdfApp from 'vue-pdf-app'
import { debounce } from 'vue-debounce'
import PaginationCustom from '@/components/table/Pagination.vue'
import PbgDetail from '@/components/simbg/PbgDetail.vue'

export default {
   name: 'SimbgPbg',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
      SweetModal,
      VuePdfApp,
      PbgDetail,
   },
   data() {
      return {
         pdf: '',
         loadingPdf: false,
         loading: true,
         serverParams: {
            columnFilters: {},
            sort: {
               field: '',
               type: '',
            },
            search: '',
            page: 1,
            perPage: 10,
         },
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'No Permohonan',
               field: 'no_izin',
               sortable: false,
               thClass: 'text-center align-middle',
            },

            {
               label: 'Jenis Permohonan',
               field: 'nm_konsultasi',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Pemilik',
               field: 'nm_pemilik',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Lokasi Bangunan',
               field: 'almt_bgn',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],
      }
   },
   computed: {
      ...mapState({
         myData: state => state.SimbgPbg.items,
         myProses: state => state.SimbgPbg.itemsProses,
         myCount: state => state.SimbgPbg.countAll,
      }),
      tanggalPenetapan() {
         return tanggal => {
            let value = ''

            if (tanggal !== '0000-00-00') {
               value = this.$moment(tanggal).format('DD MMMM YYYY')
            } else {
               value = '-'
            }

            return value
         }
      },
   },
   watch: {
      loadingPdf(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'SimbgPbg/getData',
         getDataProses: 'SimbgPbg/getProses',
         cetakIzin: 'SimbgPbg/cetakIzin',
      }),
      ...mapMutations({
         setIdIzin: 'SimbgPbg/SET_ID_IZIN',
      }),
      onDetailIzin(idIzin) {
         this.setIdIzin(idIzin)
         this.$bvModal.show('modal-detail')
      },
      onCetakIzin(nib) {
         this.loadingPdf = true
         this.cetakIzin(nib).then(res => {
            const blob = new Blob([res.data])
            this.pdf = URL.createObjectURL(blob)
            // URL.revokeObjectURL(objectURL)
            this.$refs.modalPdf.open()
            this.loadingPdf = false
         }, () => {
            this.loadingPdf = false
         })
      },
      onFilter() {

      },

      // VUE GOOD TABLE
      loadItems() {
         this.loading = true
         this.getData(this.serverParams)
      },
      updateParams(newProps) {
         // eslint-disable-next-line prefer-object-spread
         this.serverParams = Object.assign({}, this.serverParams, newProps)
      },
      onPageChangePagination(page) {
         this.updateParams({ page })
         this.loadItems()
      },
      onPerPageChangePagination(perPage) {
         this.updateParams({ perPage, page: 1 })
         this.loadItems()
      },
      onColumnFilter(params) {
         this.updateParams(params)
         this.loadItems()
      },
      onSearch: debounce(function search(params) {
         this.serverParams.search = params
         this.serverParams.page = 1
         this.loadItems()
      }, 500),
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data PBG">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="6">
               <b-form @submit.stop.prevent>
                  <div class="d-flex align-items-center justify-content-end">
                     <b-form-input
                        v-model="searchTerm"
                        class="d-inline-block mb-1"
                        placeholder="Search..."
                        autocomplete="off"
                        type="text"
                        aria-label="Search"
                        @input="onSearch"
                     />
                  </div>
               </b-form>
            </b-col>
            <b-col cols="12" md="6">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1">
                     <b-button variant="outline-secondary" size="sm" @click="onFilter()">
                        <feather-icon icon="FilterIcon" />
                     </b-button>
                     <b-button variant="outline-secondary" size="sm" @click="loadItems()">
                        <feather-icon icon="RefreshCwIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item @click="onFilter()">
                           Filter
                        </b-dropdown-item>
                        <b-dropdown-item @click="loadItems()">
                           Refresh
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>
         </b-row>

         <!-- table -->
         <vue-good-table
            ref="goodTable"
            mode="remote"
            :columns="columns"
            :rows="myData"
            :total-rows="myCount"
            :is-loading.sync="loading"
            :line-numbers="true"
            :pagination-options="{
               enabled: true,
               mode: 'pages',
            }"
            :search-options="{
               enabled: true,
               externalQuery: searchTerm,
            }"
            @on-column-filter="onColumnFilter"
            @on-search="onSearch"
         >
            <template slot="table-row" slot-scope="props">
               <!-- Column: Name -->
               <span v-if="props.column.field == 'no_izin'">
                  <b>{{ (props.row.no_izin) }}</b>
                  <hr>
                  <small>Nama : {{ props.row.nm_bgn }}</small>
                  <hr>
                  <small>Terbit : {{ props.row.tgl_terbit | moment("DD-MM-YYYY") }}</small>
               </span>

               <span v-if="props.column.field == 'nm_pemilik'">
                  <b>{{ props.row.nm_pemilik }}</b>
               </span>

               <span v-if="props.column.field == 'nm_konsultasi'">
                  {{ (props.row.nm_konsultasi) }}
                  <hr>
                  <small>Fungsi : {{ props.row.fungsi_bg }}</small>
                  <hr>
                  <small>Klasifikasi : {{ props.row.klasifikasi_bg }}</small>
               </span>

               <span v-else-if="props.column.field == 'almt_bgn'">
                  {{ (props.row.almt_bgn) }}
                  <hr>
                  <small>Kecamatan : {{ (props.row.nama_kec_bg) }}</small>
               </span>

               <span
                  v-else-if="props.column.field == 'tgl_ditetapkan'"
               >{{ tanggalPenetapan(props.row.tgl_terbit) }}</span>

               <span v-if="props.column.field == 'action'">
                  <b-button
                     v-if="props.row.s3_path"
                     size="sm"
                     block
                     variant="outline-dark"
                     class="nowrap"
                     @click="onDetailIzin(props.row.id_izin)"
                  >
                     <feather-icon icon="EyeIcon" class="mr-50" />
                     <small>Detail</small>
                  </b-button>
                  <b-button
                     v-if="props.row.s3_path"
                     size="sm"
                     block
                     variant="primary"
                     class="nowrap"
                     @click="onCetakIzin(props.row.id_izin)"
                  >
                     <feather-icon icon="FileTextIcon" class="mr-50" />
                     <small>Cetak</small>
                  </b-button>
               </span>
               <!-- Column: Common -->
               <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
               <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom
                  :total="props.total"
                  :page-length="pageLength"
                  :page-options="pageOptions"
                  :page-changed="props.pageChanged"
                  :per-page-changed="props.perPageChanged"
                  @update:page="onPageChangePagination"
                  @update:perpage="onPerPageChangePagination"
               />
            </template>
         </vue-good-table>
      </b-card-code>

      <b-modal
         id="modal-detail"
         scrollable
         size="lg"
         centered
         hide-footer
         title="Izin Detail"
      >
         <PbgDetail />
      </b-modal>

      <sweet-modal ref="modalPdf" modal-theme="light" overlay-theme="dark" width="80%">
         <!-- <pdf :src="pdf" /> -->
         <div style="height: 70vh;">
            <vue-pdf-app :pdf="pdf" />
         </div>
      </sweet-modal>
   </div>
</template>

<style scoped>
.borderless > tr > td {
   border: none;
   padding: 0em;
}

.borderless > tr > td {
   border: none;
   padding: 0em;
}
table > tbody > tr > td > span > hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}

.nowrap {
   white-space: nowrap;
}
</style>

<style>
.hidden_header {
   display: none;
}
</style>
