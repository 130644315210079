<script>
import { mapState, mapActions } from 'vuex'

export default {
   data() {
      return {
         item: {},
         table1: [],
         table2: [],
         fields: [
            { key: 'name', label: 'Person full name', tdClass: 'text-left' },
            { key: 'value', label: 'Person full name', tdClass: 'text-left' },
         ],
      }
   },
   computed: {
      ...mapState({
         myItem: state => state.SimbgPbg.item,
         myidIzin: state => state.SimbgPbg.idIzin,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
   },
   watch: {
      isLoading(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
      myItem(item) {
         this.generateTable(item)
      },
      myidIzin: {
         immediate: true,
         async handler(val) {
            await this.getItem(val).then(() => { this.item = this.myItem })
         },
      },
   },
   methods: {
      ...mapActions({
         getItem: 'SimbgPbg/getItem',
      }),
      generateTable(item) {
         const terbit = this.$moment(item.tgl_terbit).format('DD MMMM YYYY')

         this.table1.push({ name: 'Terbit', value: terbit })
         this.table1.push({ name: 'Jenis Konsultasi', value: item.nm_konsultasi })
         this.table1.push({ name: 'Nama Bangunan', value: item.nm_bgn })
         this.table1.push({ name: 'Klasifikasi Bangunan', value: item.klasifikasi_bg })
         this.table1.push({ name: 'Fungsi ', value: item.fungsi_bg })
         this.table1.push({ name: 'Luas', value: this.$options.filters.formatNumber(item.luas_bgn.replace('.', ',')) })
         this.table1.push({ name: 'Ketinggian', value: this.$options.filters.formatNumber(item.tinggi_bgn.replace('.', ',')) })
         this.table1.push({ name: 'Jumlah Lantai', value: item.jml_lantai })
         this.table1.push({ name: 'Luas Basement', value: this.$options.filters.formatNumber(item.luas_basement.replace('.', ',')) })
         this.table1.push({ name: 'Jumlah Lantai Basement', value: this.$options.filters.formatNumber(item.lapis_basement.replace('.', ',')) })
         this.table1.push({ name: 'Lokasi ', value: item.almt_bgn })
         this.table1.push({ name: 'Kec. ', value: item.nama_kec_bg })

         this.table2.push({ name: 'Nama Pemilik', value: item.nm_pemilik })
         this.table2.push({ name: 'No. Telp / Hp', value: item.no_hp })
         this.table2.push({ name: 'E-Mail', value: item.email })
         this.table2.push({ name: 'No. Identitas', value: item.no_ktp })
         this.table2.push({ name: 'Alamat', value: item.alamat })
         this.table2.push({ name: 'Kec.', value: item.nama_kecamatan })
         this.table2.push({ name: 'Kab/kota', value: item.nama_kabkota })
         this.table2.push({ name: 'prov.', value: item.nama_prov_pemilik })
      },
   },
}
</script>

<template>
   <b-card text-variant="center" class="card card-congratulations">
      <b-row>
         <b-col cols="6" lg="6" sm="12">
            <b-table
               ref="table1"
               hover
               small
               :items="table1"
               :fields="fields"
               thead-class="hidden_header"
               tbody-class="text-white"
            />
         </b-col>
         <b-col cols="6" lg="6" sm="12">
            <b-table
               hover
               small
               :items="table2"
               :fields="fields"
               thead-class="hidden_header"
               tbody-class="text-white"
            />
         </b-col>
      </b-row>
   </b-card>
</template>

<style>
.hidden_header {
   display: none;
}
</style>
